import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TechIcon from '../components/techIcon'

const frontendTechnologies = [
  'react',
  'gatsby',
  'vue',
  'javascript',
  'sass',
  'bootstrap',
  'jquery',
  'ember',
]

const backendTechnologies = [
  'node',
  'python',
  'django',
  'flask',
  'graphql',
  'sequelize',
  'postgresql',
  'mongodb',
]

// Add contentful after RTD work
const otherTechnologies = [
  'shopify',
  'wordpress',
  'drupal',
  'heroku',
  'aws',
  'babel',
  'git',
]

const sectionCSS = `
  display: grid;
  borderTop: 1px solid rgba(0,0,0, .05);
  padding: 20px;
  box-shadow: 0px 7px 9px 3px rgba(0,0,0,.2);
  grid-template-columns: 50% 50%;
  margin: 30px 0;
  width: 100%;
  @media (max-width: 700px) {
    grid-template-columns: 100%;
  }
`

const iconWrapperCSS = `
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  place-items: center;
`

const iconImgCSS = `
  padding: 15;
  margin: 0;
`

const ServicesPage = () => (
  <Layout>
    <SEO title="Technologies" />
    <section id="front-end" css={sectionCSS}>
      <div>
        <h2>Front End Technology</h2>
      </div>
      <div css={iconWrapperCSS}>
        {frontendTechnologies.map(tech => (
          <TechIcon key={tech} icon={tech} cssObj={iconImgCSS} />
        ))}
      </div>
    </section>
    <section id="back-end" css={sectionCSS}>
      <div>
        <h2>Back End Technology</h2>
      </div>
      <div css={iconWrapperCSS}>
        {backendTechnologies.map(tech => (
          <TechIcon key={tech} icon={tech} cssObj={iconImgCSS} />
        ))}
      </div>
    </section>
    <section id="other" css={sectionCSS}>
      <div>
        <h2>Additional Technology</h2>
      </div>
      <div css={iconWrapperCSS}>
        {otherTechnologies.map(tech => (
          <TechIcon key={tech} icon={tech} cssObj={iconImgCSS} />
        ))}
      </div>
    </section>
  </Layout>
)

export default ServicesPage
