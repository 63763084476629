import React from 'react'

import html from '../assets/icons/html5/html5-original.svg'
import css from '../assets/icons/css3/css3-original.svg'
import sass from '../assets/icons/sass/sass-original.svg'
import bootstrap from '../assets/icons/bootstrap/bootstrap-plain.svg'
import javascript from '../assets/icons/javascript/javascript-original.svg'
import jquery from '../assets/icons/jquery/jquery-original.svg'
import vue from '../assets/icons/vuejs/vuejs-original.svg'
import react from '../assets/icons/react/react-original.svg'
import gatsby from '../assets/icons/gatsby/gatsby.png'
import ember from '../assets/icons/ember/ember.jpg'
import handlebars from '../assets/icons/handlebars/handlebars-original.svg'
import node from '../assets/icons/nodejs/nodejs-original.svg'
import express from '../assets/icons/express/express-original.svg'
import python from '../assets/icons/python/python-original.svg'
import flask from '../assets/icons/flask/flask.png'
import django from '../assets/icons/django/django.png'
import postgresql from '../assets/icons/postgresql/postgresql-original.svg'
import graphql from '../assets/icons/graphql/graphql.png'
import mongodb from '../assets/icons/mongodb/mongodb-original.svg'
import aws from '../assets/icons/amazonwebservices/amazonwebservices-original.svg'
import babel from '../assets/icons/babel/babel-original.svg'
import heroku from '../assets/icons/heroku/heroku-original.svg'
import shopify from '../assets/icons/shopify/shopify.png'
import wordpress from '../assets/icons/wordpress/wordpress-plain.svg'
import drupal from '../assets/icons/drupal/drupal-original.svg'
import git from '../assets/icons/git/git-original.svg'
import github from '../assets/icons/github/github-original.svg'
import sequelize from '../assets/icons/sequelize/sequelize-original.svg'

const icons = {
  react: { name: 'React', logo: react },
  gatsby: { name: 'Gatsby', logo: gatsby },
  vue: { name: 'VueJS', logo: vue },
  javascript: { name: 'JavaScript', logo: javascript },
  sass: { name: 'Sass', logo: sass },
  bootstrap: { name: 'Bootstrap', logo: bootstrap },
  jquery: { name: 'jQuery', logo: jquery },
  ember: { name: 'Ember', logo: ember },
  html: { name: 'HTML5', logo: html },
  css: { name: 'CSS3', logo: css },
  handlebars: { name: 'Handlebars', logo: handlebars },
  node: { name: 'Node.js', logo: node },
  express: { name: 'Express', logo: express },
  python: { name: 'Python', logo: python },
  django: { name: 'Django', logo: django },
  flask: { name: 'Flask', logo: flask },
  graphql: { name: 'GraphQL', logo: graphql },
  sequelize: { name: 'Sequelize', logo: sequelize },
  postgresql: { name: 'Postgresql', logo: postgresql },
  mongodb: { name: 'MongoDB', logo: mongodb },
  shopify: { name: 'Shopify', logo: shopify },
  wordpress: { name: 'WordPress', logo: wordpress },
  drupal: { name: 'Drupal', logo: drupal },
  heroku: { name: 'Heroku', logo: heroku },
  aws: { name: 'Amazon Web Services', logo: aws },
  babel: { name: 'Babel', logo: babel },
  git: { name: 'Git', logo: git },
  github: { name: 'GitHub', logo: github },
}

const TechIcon = ({ icon, cssObj }) => {
  // console.log(props)
  const techIcon = icons[icon]
  return (
    <img
      css={cssObj || { padding: 5 }}
      src={techIcon.logo}
      alt={techIcon.name}
      title={techIcon.name}
    />
  )
}

export default TechIcon
